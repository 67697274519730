 <template>
  <div id="app">
    <router-view v-if="allow_view == true || permission_links.includes($route.path)" />
    <p class="text-center" v-else>Not Authorized to access</p>
    <loading
      :active.sync="$store.state.isLoading"
      :is-full-page="true"
      color="#5664d2"
      loader="dots"
    ></loading>
  </div>
</template>
<style>
.overall-count .vue-switcher input {
  display: none;
}
.overall-count .vue-switcher--bold div {
  top: 0 !important;
  height: 20px !important;
  width: 40px !important;
}
.overall-count .vue-switcher--bold div:after {
  margin-left: -18px !important;
  top: 2px !important;
}
.overall-count .vue-switcher--bold--unchecked div:after {
  left: 22px !important;
}
.overall-count .vue-switcher div:after {
  height: 15px !important;
  width: 15px !important;
}
</style>
<script>
import appConfig from "@/app.config";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import {menuItems} from "./components/menu";
export default {
  name: "app",
  components: {
    Loading,
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  mounted() {
    this.enableInterceptor();
  },
  data() {
    return {
      axiosInterceptor: null,
      pending_req: 0,
      permission_links : [],
      allow_view : true,
    };
  },
  watch : {
    '$store.state.status.loggeduser'(v) {
        if(v == true) {
          this.getPermissionLinks();
        }
    }
  },
  methods: {
    getPermissionLinks(){
      let menus = menuItems.filter(m => typeof m.subItems != 'undefined').map(m => m.subItems);
      let sub_menus = menus.filter(m => typeof m.subItems != 'undefined').map(m => m.subItems);
      let permissions = this.$store.state.authfack.user.permissions.map(m => m.name);
      let list = [];
      menus.forEach(m => {
        m.forEach(sm => {
           if(permissions.includes(sm.permission)){
             list.push(sm.link);
           }
        });
      });
      sub_menus.forEach(m => {
        m.forEach(sm => {
          if(permissions.includes(sm.permission)){
            list.push(sm.link);
          }
        });
      });
      this.permission_links = list;
    },
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.pending_req++;
          this.$store.commit("setLoader", true);
          return config;
        },
        (error) => {
          this.$store.commit("setLoader", false);
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.pending_req--;
          if (this.pending_req == 0) {
            this.$store.commit("setLoader", false);
          }
          return response;
        },
        function (error) {
          this.pending_req--;
          if (this.pending_req == 0) {
            this.$store.commit("setLoader", false);
          }
          return Promise.reject(error);
        }
      );
    },

    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor);
    },
  },
};
</script>
