import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  state: { token: null, isLoading: false, permissions : [], role : 'AnalyticsSuperAdmin' },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setLoader(state, payload) {
      state.isLoading = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload
    },
    setRole(state, payload) {
      state.role = payload
    },
    setUser(state,payload) {
      state.authfack.user = payload;
    }
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
